import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Layout from '@components/Layout';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Squiggle from '@images/svgs/squiggle.svg';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hero from '@components/Hero';
import SEO from '@components/Seo';
import Button from '@components/Button';
import { MenuItem, Stack, TextField, Typography, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import queryString from 'query-string';
import { useLocation } from '@reach/router';

const Contact = ({
    data: {
        page: {
            frontmatter: {
                title,
                description,
                graphic: { publicURL: graphic },
            },
        },
        offices: { edges: offices },
    },
}) => {
    const location = useLocation();
    const [marker, setMarker] = useState({
        longitude: offices?.[0]?.node?.frontmatter?.longitude,
        latitude: offices?.[0]?.node?.frontmatter?.latitude,
    });
    const [viewport, setViewport] = useState({
        zoom: 15,
        longitude: offices?.[0]?.node?.frontmatter?.longitude,
        latitude: offices?.[0]?.node?.frontmatter?.latitude,
    });

    const { service: queryService } = queryString?.parse(location?.search);
    const services = [
        {
            value: 'app-development',
            title: 'Let\'s get your app made',
            name: 'App Development'
        },
        {
            value: 'graphic-design',
            title: 'Let\'s get designing',
            name: 'Graphic Design'
        },
        {
            value: 'web-platforms',
            title: 'Let\'s make something amazing',
            name: 'Web Platforms'
        }
    ]
    
    const budgets = [
        '£150-£499',
        '£500-£999',
        '£1,000-£2,499',
        '£2,500-£4,999',
        '£5,000-£9,999',
        '£10,000-£14,999',
        '£15,000-£29,999',
        '£30,000-£49,999',
        '£50,000-£99,999',
        '£100,000-£174,999',
        '£175,000-£249,999',
        '£250,000+'
    ];
    
    const [service, setService] = useState('app-development');
    const formTitle = services.find(title => title.value === service)?.title ?? 'Contact Us';
    const [budget, setBudget] = useState(budgets[5]);
    const theme = useTheme();
    const myRef = useRef(null);
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleViewportChange = (viewport, interactionState) => {
        // prevent trigger on initialisation without user intervention
        if (!interactionState?.isPanning && !interactionState?.isZooming) return;

        setViewport({
            longitude: viewport.longitude,
            latitude: viewport.latitude,
            zoom: viewport.zoom,
        });
    };



    useEffect(() => {
        if (services.find(service => service.value === queryService)) {
            setService(queryService)
        }
    }, [])

    return (
        <Layout>
            <SEO title="Contact" />
            <Hero
                title={title}
                description={description}
                image={graphic}
                imageWrapperClassName="-ml-12"
            />

            <div className="max-w-6xl px-5 py-24 xl:px-0 mx-auto">
                <Box sx={mobile ? { width: '100%' } : { width: '50%', margin: 'auto' }} ref={myRef}>
                    <form
                        name="contact-form"
                        method="post"
                        data-netlify="true"
                        netlify-honeypot="bot-field"
                        data-netlify-recaptcha="true"
                        encType="application/x-www-form-urlencoded"
                    >
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contact-form" />
                        <Stack direction="column" spacing={3} sx={{ pb: 8 }}>
                            <Typography variant="h4" component="h2" display="block">
                                {formTitle}
                            </Typography>
                            <TextField
                                name="email"
                                type="email"
                                label="Email"
                                required
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                name="name"
                                type="text"
                                label="Name"
                                required
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                name="service"
                                select
                                type="text"
                                required
                                label="Services"
                                margin="normal"
                                value={service}
                                onChange={e => setService(e.target.value)}
                                variant="outlined"
                            >
                                {services?.map((service, index) => (
                                    <MenuItem key={index} value={service?.value}>
                                        {service?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                name="budget"
                                select
                                type="text"
                                required
                                label="Budget Size"
                                margin="normal"
                                value={budget}
                                onChange={e => setBudget(e.target.value)}
                                variant="outlined"
                            >
                                {budgets?.map((budget, index) => (
                                    <MenuItem key={index} value={budget}>
                                        {budget}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <div data-netlify-recaptcha="true"></div>
                            <Button type="submit">Submit</Button>
                        </Stack>
                    </form>
                </Box>
                <div className="px-3">
                    <h2>Where we work from</h2>
                </div>

                <div className="mt-8 flex flex-wrap">
                    {[offices?.[0]]?.map(
                        ({
                            node: {
                                frontmatter: {
                                    name,
                                    type,
                                    image,
                                    place,
                                    street,
                                    zip,
                                    latitude,
                                    longitude,
                                    direction,
                                    city,
                                    country,
                                },
                            },
                        }) => {
                            return (
                                <div
                                    className={`w-full flex justify-center  mb-8 ${styles.officeCardParent}`}
                                >
                                    <div className={` w-full ${styles.card}`}>
                                        <Img
                                            className={styles.cardImage}
                                            fluid={image?.childImageSharp.fluid}
                                        />

                                        <div
                                            className={`p-8 flex flex-col items-center ${styles.cardBody}`}
                                        >
                                            <h4 className="my-1">{name}</h4>
                                            {!!type && (
                                                <p className="mt-1 mb-3 font-bold text-sm">
                                                    {type}
                                                </p>
                                            )}

                                            <Squiggle />
                                            <div className="mt-8 text-black text-sm text-center">
                                                <p className=" my-1">{place}</p>
                                                <p className=" my-1">{street}</p>
                                                <p className=" my-1">{city}</p>
                                                <p className="my-1">
                                                    {zip}, {country}
                                                </p>
                                            </div>
                                            <div className="mt-8 flex">
                                                <a href={direction} target="_blank">
                                                    <button className="px-5 py-3  bg-primary outline-none border-none text-black font-bold cursor-pointer mr-5">
                                                        Directions
                                                    </button>
                                                </a>
                                                <button
                                                    onClick={() => {
                                                        setMarker({ longitude, latitude });
                                                        setViewport({
                                                            ...viewport,
                                                            longitude,
                                                            latitude,
                                                        });
                                                    }}
                                                    className="px-5 py-3  bg-primary outline-none border-none text-black font-bold cursor-pointer"
                                                >
                                                    View on map
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
            <div className=" w-full">
                <ReactMapGL
                    mapStyle="mapbox://styles/mapbox/outdoors-v10?optimize=true"
                    mapboxApiAccessToken="pk.eyJ1IjoiYmlncml2YWwiLCJhIjoiY2phMzRsanUzMHV6ajJxbGZzdDZsamtqayJ9.PngeT-c1grgQoIMXMhkBUA"
                    zoom={viewport?.zoom}
                    maxZoom={20}
                    longitude={viewport?.longitude}
                    latitude={viewport?.latitude}
                    height={400}
                    width="100%"
                    onViewportChange={handleViewportChange}
                >
                    <NavigationControl
                        onViewportChange={viewport =>
                            handleViewportChange(viewport, { isZooming: true })
                        }
                        className={styles.mapZoom}
                        showCompass={false}
                    />

                    {!!marker && (
                        <Marker
                            longitude={marker?.longitude}
                            latitude={marker?.latitude}
                            offsetLeft={-19}
                            offsetTop={-8}
                        >
                            <FontAwesomeIcon className={styles.marker} icon="map-marker-alt" />
                        </Marker>
                    )}
                </ReactMapGL>
            </div>
            <div className="bg-white">
                <div className="max-w-6xl container px-5 xl:px-0 mx-auto py-24 text-center">
                    <h2 className="my-2">How can we help?</h2>
                    <p className="text-xl mt-2 font-medium  mx-auto max-w-4xl">
                        It might be easier than you think. Get in touch today.
                    </p>
                    <div className="mt-8 flex flex-col lg:flex-row justify-center items-center ">
                        <Button
                            text="Phone us"
                            href="tel:01206259355"
                        />

                        <Button
                            secondary
                            text="Open chat"
                            onClick={() => window?.FB?.CustomerChat?.show(true)}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query ContactQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: contact_title
                description: contact_description
                graphic: contact_graphic {
                    publicURL
                }
            }
        }
        offices: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/office.md/" } }
            sort: { fields: frontmatter___office_sort_num }
        ) {
            edges {
                node {
                    frontmatter {
                        name: office_name
                        type: office_type
                        image: office_image {
                            childImageSharp {
                                fluid(maxWidth: 900) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        place: office_venue_place
                        street: office_venue_street
                        city: office_venue_city
                        zip: office_venue_zip
                        country: office_venue_country
                        direction: office_venue_direction
                        latitude: office_venue_latitude
                        longitude: office_venue_longitude
                    }
                }
            }
        }
    }
`;

export default Contact;
